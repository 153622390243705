import React from "react";
import { Row, Col } from "react-bootstrap";

import Section from "../Section";
import Heading from "../Heading";

import styles from "./index.module.scss";

class BucketGroupSticky extends React.Component {
	render() {
		return (
			<Section>
				<Row className={styles.bucketGroupRow}>
					<Col lg={6} className={styles.bucketGroupHeadingColumn}>
						<Heading
							text={this.props.headingText}
							limitMargin={true}
							themeType={this.props.themeType}
						/>
					</Col>
					<Col lg={6} className={styles.bucketColumn}>
						{this.props.children}
					</Col>
				</Row>
			</Section>
		);
	}
}

export default BucketGroupSticky;
