import React from "react";
import { Row, Col } from "react-bootstrap";
import { injectIntl } from 'gatsby-plugin-intl';

import Layout from "../../components/Layout";
import Background from "../../components/Background";
import Section from "../../components/Section";
import Hero from "../../components/Hero";
import MotifColumn from "../../components/MotifColumn";
import PreviewCompatibleImage from "../../components/PreviewCompatibleImage";
import BucketGroupSticky from "../../components/BucketGroupSticky";
import BucketForTwoColumn from "../../components/BucketForSticky";
import PartnerCTA from "../../components/PartnershipCTA";
import BucketGroup from "../../components/BucketGroup";
import Bucket from "../../components/Bucket";
import Questions from "../../components/Questions";

import iconHedging from "../../img/icon-hedging-purple.svg";
import iconGlobal from "../../img/icon-global-purple.svg";
import iconFullyHosted from "../../img/icon-fully-hosted.svg";
import iconAdminDash from "../../img/icon-admin-dash.svg";
import iconCustomizable from "../../img/icon-customizable.svg";

import styles from "../partnerships/index.module.scss";

import logoOanda from "../../img/logo-partnership1.jpg";
import logoSMPS from "../../img/logo-partnership2.jpg";
import logoCurrencyCloud from "../../img/logo-partnership3.jpg"
import logoWicari from "../../img/logo-partnership4.jpg"

const PartnershipPage = ({intl}) => {
  return (
    <Layout>
      <Background themeType={"light"}>
        <Hero
          type={"partnership"}
          backgroundType={"dark"}
          className={styles.heroPartnership}
        >
          <MotifColumn>
            <span className={styles.subHeading}>Shift for your clients</span>
            <h1 className={styles.heading}>Partner with Shift Connect</h1>
          </MotifColumn>
          
        </Hero>
        <Section className={styles.partnershipSection}>
          <Row>
            <Col xs={12}>
                <h1>Expand Your Offerings with our Best-in-Class Capabilities</h1>
                <p>
                    Work with one of the most trusted FinTech firms to find the right solution for your clients.
                     Whether you’re looking at Shift as a way to start your new company, improve your existing offering or simply add a complementary revenue stream to your core business services, we have a solution for you. We offer cross-border payments and foreign exchange solutions to our global community and this partnership opportunity empowers you to do the same.
                </p>
            </Col>
          </Row>
          <Row className={styles.partnerLogoRow}>
            <Col xs={6} md={3} className={styles.partnerLogoColumn}>
              <PreviewCompatibleImage
                imageInfo={{
                  image: logoOanda,
                  alt: "Oanda Logo",
                  width: 132,
                  height: 60
                }}
              />
            </Col>
            <Col xs={6} md={3} className={styles.partnerLogoColumn}>
              <PreviewCompatibleImage
                imageInfo={{
                  image: logoSMPS,
                  alt: "SMPS Logo"
                }}
              />
            </Col>
            <Col xs={6} md={3} className={styles.partnerLogoColumn}>
              <PreviewCompatibleImage
                imageInfo={{
                  image: logoCurrencyCloud,
                  alt: "CurrencyCloud Logo"
                }}
              />
            </Col>
            <Col xs={6} md={3} className={styles.partnerLogoColumn}>
              <PreviewCompatibleImage
                imageInfo={{
                  image: logoWicari,
                  alt: "Wicari Logo"
                }}
              />
            </Col>
          </Row>
        </Section>
        <BucketGroupSticky headingText='Partner with Shift'>
          <BucketForTwoColumn
            imageURL={iconHedging}
            title= 'Branded Solution'
            description='A complete offering under your branding. Offer your clients a full suite of products, including FX spot and forward, domestic and international payments, and dedicated currency accounts, all under your brand. Your business’ new platform can be up and running in as little as 4 weeks, without any investment required on your end. We’ll manage all compliance and operational activities, leaving you to focus on growing your business. Through segregated access to our back-office platform, you will always be in control of your clients. This option allows you the flexibility to offer a global payments and foreign exchange platform to your clients with complete brand consistency from start to finish.'
          />
          <BucketForTwoColumn
            imageURL={iconGlobal}
            title='Affiliate Partnerships '
            description='Create additional value for you and your clients while helping them grow their international business potential. Whether you are an accountant, financial advisor, or simply work with international companies, you can be assured each one of your customers will receive exceptional service from start to finish. Our affiliate program allows you to refer your community members to Shift, your trusted FX and global payments partner, all while earning extra revenue. The perfect option for industry-adjacent businesses, this is the perfect opportunity to maintain trust and satisfaction among your clientele and maximize the impact of your overall consumer experience.'
          />
        </BucketGroupSticky>
        <PartnerCTA />
        <BucketGroup
          headingText='Why Partner With Shift?'
          subHeadingText='We are committed to the success of your business and the satisfaction of your customers.'
        >
          <Bucket
            imageURL={iconFullyHosted}
            title= 'First-Class FX Hedging Capabilities'
            description= ' Offer your clients unrivalled hedging expertise on forward contracts with tenors up to one year in over 35 currencies.'
          />
          <Bucket
            imageURL={iconAdminDash}
            title='Extensive Product offerings'
            description='Give your clients access to FX capabilities in over 130 currencies, domestic and international payments and named accounts. '
          />
          <Bucket
            imageURL={iconCustomizable}
            title='Global Regulatory Footprint'
            description='Don’t limit your business: build your growth plans on our licenses, covering three continents and over 30 countries.'
          />
        </BucketGroup>
        <Questions />
      </Background>
    </Layout>
  );
};

export default injectIntl(PartnershipPage);
