import React from "react";
import { Row, Col } from "react-bootstrap";

import Section from "../Section";
import Heading from "../Heading";

import styles from "./index.module.scss";

class BucketGroup extends React.Component {
	render() {
		return (
			<Section extraLeftPadding={this.props.extraLeftPadding}>
				<Row className={styles.bucketGroupRow}>
					<Col>
						<Row>
							<Col className={styles.bucketGroupHeading}>
								<Heading text={this.props.headingText} />

								{this.props.subHeadingText && (
									<h2 className={styles.subHeadingText}>
										{this.props.subHeadingText}
									</h2>
								)}
							</Col>
						</Row>
						<Row>{this.props.children}</Row>
					</Col>
				</Row>
			</Section>
		);
	}
}

export default BucketGroup;
