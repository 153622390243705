import React from "react";
import classNames from "classnames/bind";
import { Col } from "react-bootstrap";

import styles from "./index.module.scss";

let classNamesBound = classNames.bind(styles);

class BucketForSticky extends React.Component {
	render() {
		let bucketClasses = classNamesBound({
			bucket: true,
			[`${this.props.themeType}`]: this.props.themeType
		});

		return (
			<Col className={bucketClasses}>
				{this.props.imageURL && (
					<div className={styles.imageWrapper}>
						<img
							src={this.props.imageURL}
							width="42"
							height="42"
							alt=""
						/>
					</div>
				)}
				<div className={styles.contentWrapper}>
					<h3 className={styles.title}>{this.props.title}</h3>
					<p>{this.props.description}</p>
					{this.props.linkText && (
						<a href={this.props.linkURL} className={styles.link}>
							{this.props.linkText}
						</a>
					)}
				</div>
			</Col>
		);
	}
}

export default BucketForSticky;
