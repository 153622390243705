import React from "react";
import { Container, Row, Col} from "react-bootstrap";
import Section from "../Section";
import Button from "../Button";

import { injectIntl } from 'gatsby-plugin-intl';

import IconMail from "../../img/MailIcon.png";

import PreviewCompatibleImage from "../PreviewCompatibleImage";
import styles from "./index.module.scss";

import securityStandardsPhoto from "../../img/security-1.jpg";



class PartnerCTA extends React.Component {
	render() {
		return (
			<Container className={styles.fixedContainer}>
				<div className={styles.imageWrapper}>
					<PreviewCompatibleImage
						imageInfo={{
							image: securityStandardsPhoto,
							alt: ""
						}}
						roundedCorners={true}
					/>
				</div>
	
            <div className={styles.partnershipWrapper}>
                <Section className={styles.securitySection}>
                <Row className={styles.badge}>
                    <Col>
                        <Row>
                            <Col
                                xs={12}
                                lg={true}
                                className={styles.headingColumn}
                            >
                                <div className={styles.headingWrapper}>
                                    <img
                                        src={IconMail}
                                        className={styles.icon}
                                        width="65"
                                        height="65"
                                        alt=""
                                    />
                                    <h1 className={styles.heading}>
                                        We want to hear from you
                                    </h1>
                                </div> 
                            </Col>
                         </Row>
                        <Row>   
                            <Col xs={12} lg={true}>
                                <p>
                                If you’d like to partner with Shift or learn more about how one of our partnership opportunities can support your community, we want to hear from you. Fill out our partnership form and tell us a bit about our business and your clients’ needs. 
                                </p>
                                <Button
                                    href= "https://shiftconnect.typeform.com/to/Hf4H9znU"
                                    color={"darkPurple"}
                                    className={styles.button}
                                >
                                    Partner with Us
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Section>
        </div>
        </Container>
		);
	}
}

export default injectIntl (PartnerCTA);