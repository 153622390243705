import React from "react";
import classNames from "classnames/bind";
import PropTypes from "prop-types";
import Img from "gatsby-image";

import styles from "./index.module.scss";

let classNamesBound = classNames.bind(styles);

class PreviewCompatibleImage extends React.Component {
  render() {
    const { alt = "", childImageSharp, image } = this.props.imageInfo;
    let imageClasses = classNamesBound({
      image: true,
      roundedCorners: this.props.roundedCorners
    });

    if (!!image && !!image.childImageSharp) {
      return (
        <Img
          fluid={image.childImageSharp.fluid}
          alt={alt}
          className={imageClasses}
        />
      );
    }

    if (!!childImageSharp) {
      return (
        <Img fluid={childImageSharp.fluid} alt={alt} className={imageClasses} />
      );
    }

    if (!!image && typeof image === "string")
      return <img src={image} alt={alt} className={imageClasses} />;

    return null;
  }
}

PreviewCompatibleImage.propTypes = {
  imageInfo: PropTypes.shape({
    alt: PropTypes.string,
    childImageSharp: PropTypes.object,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired
  }).isRequired
};

export default PreviewCompatibleImage;
